.project-card{
    display: flex;
    justify-content: center;
    align-content: center;
}
.project-card a{
    font-weight: bold;
}
.project-card span{
    font-weight: bold;
}

.skills-list{
    display: flex;
    flex-wrap: wrap;
}
.skills-list li{
    margin: 5px;
}
.skils-icons{
    font-size:4em;
}

.icons-contact{
    font-size:3rem;
    padding-right:10px;
}
.card-description{
        padding: 5px;
        height: max-content;
}


@media only screen and (min-width: 750px){
    .card-description{
        padding: 5px;
        height: 170px;
    }
}

@media only screen and (min-width: 900px){
    .card-description{
        padding: 5px;
        height: 180px;
    }
}